import React, {useEffect, useState} from "react";
import './index.less'
import {IFormItemProps} from "@/components/FormItemEx/interface";
import ZImage from "@/components/Image";

export interface ZUploadProps extends IFormItemProps {
    children?: any
    upload?: any
    multiple?: boolean // 是否支持多选文件。开启后按住 ctrl 可选择多个文件,默认关闭
    maxCount?: number
    value?: string
    hideName?: true
    title?: React.ReactNode
}

const ZUpload = (props:ZUploadProps) => {
    const {children, upload, multiple = false, width, onChange, hideName = false, extra = {}, title = '上传'} = props
    const {listType = 'picture'} = extra
    const [file, setFile] = useState<string>('')
    const [fileName, setFileName] = useState<string>('')
    const customRequest = (e: any) => {
        if (e?.target?.files?.length) {
            const s = e.target.value?.split(/\\/)
            setFileName(s[s.length - 1])
            upload?.(e, (url: string) => {
                setFile(url)
                onChange?.(url)
                e.target.value = ''
            })
            e.target.value = ''
        }
    }

    useEffect(() => {
        if (props.value !== file) {
            onChange?.(props.value)
            setFileName('')
            setFile(props.value as string)
        }
    }, [props.value])


    const content = {
        'picture': <div className={`zc-upload-btn ${!file && 'border' || ''}`}>
            {
                file
                &&
                <ZImage src={file} className='icon48'/>
                ||
                title
            }
        </div>,
        'picture-card':
            <div className={`zc-upload-pic ${!file && 'border-dashed' || ''}`}>
                {
                    file
                    &&
                    <ZImage closeable onDelete={() => setFile('')} src={file}/>
                    ||
                    '＋'
                }
            </div>,
        'text':
            <div>
                <div className='zc-upload-text border pzr ovh'>
                    <input type="file" className='zc-upload-input' onChange={customRequest} multiple={multiple}/>
                    文件上传
                </div>
                {!hideName && <div className='ovh'>
                    <a href={file} className='color-blue' target='_blank'>{fileName || file}</a>
                </div> || ''}
            </div>,
    }

    return (
        <div className='zc-upload-wrapper' style={{width: listType === 'picture-card' && '62px' || width}}>
            {
                listType !== 'text'
                &&
                <input type="file" className='zc-upload-input' onChange={customRequest} multiple={multiple}/>
                ||
                ''
            }
            {children || content[listType]}
        </div>
    )
}
export default ZUpload

