import React, {useEffect, useState} from "react";
import './index.less'
import ZVideo from "@/components/Video";
import {checkFileType, isVideo} from "@/components/Image/helper";

export type ZImageProps = {
    src?: string,
    alt?: string,
    className?: string
    closeable?: boolean
    onDelete?: () => void
    list?: string[]
    height?: number
    autoplay?: boolean
    controls?: boolean
    preview?: boolean
    style?: any
    showName?: boolean
}


const ZImage = (props: ZImageProps) => {
    const {
        showName = false,
        src = '',
        alt,
        className,
        closeable = false,
        onDelete,
        list = [],
        height = 98,
        autoplay = false,
        controls,
        preview = true,
        style = {}
    } = props
    const [name, setName] = useState<string>('')

    useEffect(() => {
        if (src) {
            const label: string = src.split('/').pop() as string
            setName(label)
        }
    }, [src])

    const onClick = (e: any) => {
        if (!preview || checkFileType(src) !== 'video') {
            return
        }
        let press: boolean = true
        let scale: number = 1
        let rotate: number = 0
        e.stopPropagation()
        let img: any = document.createElement(isVideo(src) && 'video' || 'img')
        img.className = 'zc-mode-img'
        img.src = src
        if (isVideo(src)) {
            img.setAttribute('controls', 'controls')
            img.play()
        }
        const len = list.length
        let current = src
        const mode = document.createElement('div')
        if (list.length) {
            // 上一张
            const leftArrow = document.createElement('button')
            leftArrow.className = 'zc-img-left-arrow'
            leftArrow.innerText = '上一张'
            mode.appendChild(leftArrow)

            leftArrow.addEventListener('click', (e: any) => {
                e.stopPropagation()
                mode.removeChild(img)
                const index = list.findIndex((url: string) => url === current)
                current = list[Math.max(0, index - 1)]
                if (isVideo(current)) {
                    img = document.createElement('video')
                    img.setAttribute('controls', 'controls')
                    img.src = current
                    img.play()
                } else {
                    img = document.createElement('img')
                    img.className = 'zc-mode-img'
                    img.src = current
                }
                mode.appendChild(img)
                img.addEventListener('click', (e: any) => e.stopPropagation())
            })

            // 下一张
            const rightArrow = document.createElement('button')
            rightArrow.className = 'zc-img-right-arrow'
            rightArrow.innerText = '下一张'
            mode.appendChild(rightArrow)
            rightArrow.addEventListener('click', (e: any) => {
                e.stopPropagation()
                mode.removeChild(img)
                const index = list.findIndex((url: string) => url === current)
                current = list[Math.min(len - 1, index + 1)]
                if (isVideo(current)) {
                    img = document.createElement('video')
                    img.src = current
                    img.setAttribute('controls', 'controls')
                    img.play()
                } else {
                    img = document.createElement('img')
                    img.className = 'zc-mode-img'
                    img.src = current
                }

                mode.appendChild(img)
                img.addEventListener('click', (e: any) => e.stopPropagation())
            })

        }

        // 逆时针旋转
        const leftRotate = document.createElement('div')
        leftRotate.className = 'zc-img-left-rotate'
        mode.appendChild(leftRotate)
        leftRotate.addEventListener('click', (e: any) => {
            e.stopPropagation()
            rotate -= 90
            img.style.transform = `scale(${scale}) rotate(${rotate}deg)`
        })

        // 顺时针旋转
        const rightRotate = document.createElement('div')
        rightRotate.className = 'zc-img-right-rotate'
        mode.appendChild(rightRotate)
        rightRotate.addEventListener('click', (e: any) => {
            e.stopPropagation()
            rotate += 90
            img.style.transform = `scale(${scale}) rotate(${rotate}deg)`
        })
        mode.appendChild(img)
        mode.className = 'zc-mode-wrapper'
        img.addEventListener('click', (e: any) => e.stopPropagation())
        mode.addEventListener('click', () => document.body.removeChild(mode))

        // 鼠标滚动 图片放大 缩小
        window.addEventListener('wheel', (e: any) => {
            scale = e.deltaY > 0 && Math.max(0.8, scale - 0.1) || Math.min(5, scale + 0.1)
            img.style.transform = `scale(${scale}) rotate(${rotate}deg)`
        })
        document.body.appendChild(mode)
        const handleKeydown = (e: any) => {
            if (e.keyCode === 27 && press && mode) {
                document.body.removeChild(mode)
                window.removeEventListener("keydown", handleKeydown)
            }
            if ((e.keyCode === 39 || e.keyCode === 40) && press) {
                e.stopPropagation()
                const index = list.findIndex((url: string) => url === current)
                current = list[Math.min(len - 1, index + 1)]
                img.src = current
            }
            if ((e.keyCode === 37 || e.keyCode === 38) && press) {
                e.stopPropagation()
                const index = list.findIndex((url: string) => url === current)
                current = list[Math.max(0, index - 1)]
                img.src = current
            }
            press = false
        }
        const handleKeyup = () => press = true

        window.addEventListener('keydown', handleKeydown)
        window.addEventListener('keyup', handleKeyup)
    }
    return (
        <div className={`zc-image-wrapper ${className}`}>
            <div className='flex align-items-center justify-center' style={{width: '100%', height: '100%'}}
                 onClick={onClick}>
                {
                    checkFileType(src) === 'video'
                    &&
                    <ZVideo src={src} height={height} autoplay={autoplay} controls={controls}/>
                    ||
                    checkFileType(src) === "pdf"
                    &&
                    <div className={className} title={alt || name}>
                        <a href={src} target='_blank'><img src="/images/PDF.png" alt={alt || name}/></a>
                    </div>
                    ||
                    checkFileType(src) === "zip"
                    &&
                    <div className={className} title={alt || name}>
                        <a href={src} target='_blank'><img src="/images/zip.png" alt={alt || name}/></a>
                    </div>
                    ||
                    checkFileType(src) === "word"
                    &&
                    <div className={className}>
                        <a href={src} target='_blank'><img src="/images/word.png" alt={alt || name}/></a>
                    </div>
                    ||
                    <img src={src} alt={alt || name} style={style}/>
                }

            </div>
            {
                closeable
                &&
                <div className="zc-image-close" onClick={onDelete && onDelete}> × </div>
                ||
                ''
            }
            {showName && <div className='f12 mt10 file-label'>{alt || name}</div> || ''}
        </div>
    )
}

export default ZImage

