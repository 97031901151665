import React, {useState} from "react";
import './index.less';
import {IFormItemProps} from "../FormItemEx/interface";


interface Props extends IFormItemProps {
    readOnly?: boolean
}
const ZTextArea = (props:Props)=>{
    const {
        width,
        size = 'large',
        placeholder = '',
        type = 'input' && 'text',
        border,
        disabled,
        onBlur,
        extra = {},
        value = ''
    } = props;
    const {areaHeight} = extra
    //@ts-ignore
    const [active,setActive] = useState<string>('')
    const onChange = (e: any) => {
        const value = e?.target?.value
        props?.onChange(type === 'number' && Number(value) || value)
    }

    const onFocus = () => {
        setActive(border && 'no-active' || 'input-active')
        if (props?.onFocus) {
            props.onFocus?.(value)
        }
    }

    const getActiveClass = () => {
        if (!border) {
            if (active) {
                return 'black'
            } else {
                return 'color-333'
            }
        } else {
            return 'black'
        }
    }


    return (
        <div className={`zcinput-wrapper ${border && 'zcinput-wrapper-bottom'} ${active}`} style={{width}} >
            <textarea className={`zcinput ${size} ${getActiveClass()}`}
                      disabled={disabled}
                      onChange={onChange}
                      onFocus={onFocus}
                      onBlur={(e: any) => {
                          onBlur && onBlur(e)
                          setActive('')
                      }}
                      placeholder={placeholder}
                      value={value}
                      style={{flex: 1, height: areaHeight, padding: '8px 3px', fontSize: '14px'}}
            />
        </div>)
}
export default ZTextArea;