import React, {useEffect, useState} from "react";
import './index.less';
import {IFormItemProps} from "../interface";

type borderedType = 'all' | 'bottom' | 'none'


interface Props extends IFormItemProps {
    errorMsg?: string
    min?: any
    readOnly?: boolean
    bordered?: borderedType
}


const borderedMap = {
    'all': 'zc-input-wrapper',
    'bottom': 'zc-input-bottom',
    'none': 'zc-input-none'
}
const activeMap = {
    'all': 'input-active',
    'bottom': 'input-bottom-active',
    'none': 'input-none-active'
}
const errMap = {
    'all': 'errorMsg',
    'bottom': 'errorMsg-bottom',
    'none': 'errorMsg'
}

const ZInput = (props: Props) => {
    const {
        extra,
        // clearable = true,
        width,
        size = 'large',
        placeholder,
        type = 'input' && 'text',
        disabled,
        onBlur,
        errStatus = false,
        errorMsg = '',
        readOnly = false,
        min,
        value = '',
        bordered = 'all',
        align = 'left',
        mode,
        onKeyDown,
        onKeyUp,
        filter = false
    } = props;
    //@ts-ignore
    const [active, setActive] = useState<string>('')
    const [errs, setErrs] = useState<string>('')

    useEffect(() => {
        setErrs(errorMsg)
    }, [errorMsg])

    useEffect(() => {
        if (value) {
            setErrs('')
        }
    }, [value])

    const onChange = (e: any) => {
        if (type === 'picker') {

        } else {
            const value = e.target.value
            props?.onChange?.(type === 'number' && Number(value) || value)
        }
    }
    const onFocus = (e: any) => {
        const value = e.target.value
        e.target.setSelectionRange(0, value.length)
        setActive(activeMap[bordered])
        props.onFocus?.(value)
    }
    const onInputBlur = (e: any) => {
        if (!filter) {
            onBlur?.(e)
        }
        setActive('')
    }
    // 获取class
    const getClassName = () => `${borderedMap[bordered]} ${active} ${(!!errs || errStatus) && errMap[bordered] || '2'}`
    // 属性
    const inputAttr = () => {
        return {
            disabled,
            readOnly,
            placeholder,
            type,
            className: `zcinput ${mode === 'table' ? 'full' : size}`,
            min: min ?? 1,
            value,
            onInput: onChange,
            onFocus,
            onBlur: onInputBlur,
            onKeyUp,
            onKeyDown
        }
    }
    // 清空
    // const onClear = () => {
    //     const e = {target: {value: ''}}
    //     onChange(e)
    // }
    //

    return (
        <div className={getClassName()} style={{width, background: disabled && '#eee' || ''}}>
            <input  {...inputAttr()} style={{textAlign: align}}/>
            {extra?.suffix && <span className='color-666'>{extra.suffix}</span> || ''}
            {/*{clearable && defaultValue && <label className='zc-clear-icon' onClick={onClear}>×</label>}*/}
        </div>
    )
}

export default ZInput;