import React from "react";
import './index.less'

export interface IVideoProps {
    src: string
    width?: number
    height?: number
    autoplay?: boolean
    controls?: boolean
}

const ZVideo = (props: IVideoProps) => {
    const {src, height = 98, autoplay = false, controls = false} = props
    return (
        <div className='zc-video'>
            {!autoplay && <div className="zc-play">▲</div> || ''}
            <video height={height} autoPlay={autoplay} controls={controls}>
                <source src={src}/>
            </video>
        </div>
    )
}
export default ZVideo