const videoType = ['wmv', 'asf', 'asx', 'rm', 'rmvb', 'mpg', 'mpeg', 'mpe', '3gp', 'mov', 'mp4', 'm4v', 'avi', 'dat', 'mkv', 'flv', 'vob']
const pdfType = ['pdf', 'PDF']
const zipType = ['rar', 'zip', 'arj', 'z', 'lzh', 'jar']
const wordType = ['doc', 'docx']

export const isVideo = (url: string) => {
    const result: string[] = url.split('.')
    const suffix: string = result[result.length - 1]
    return videoType.includes(suffix.toLowerCase()) && true || false
}
export const isPDF = (url: string) => {
    const result: string[] = url.split('.')
    const suffix: string = result[result.length - 1]
    return pdfType.includes(suffix.toLowerCase()) && true || false
}

export const checkFileType = (url: string) => {
    const result: string[] = url?.split?.('.') || []
    const suffix: string = result[result.length - 1] || ''
    if (videoType.includes(suffix.toLowerCase())) {
        return 'video'
    }
    if (pdfType.includes(suffix.toLowerCase())) {
        return 'pdf'
    }
    if (zipType.includes(suffix.toLowerCase())) {
        return 'zip'
    }
    if (wordType.includes(suffix.toLowerCase())) {
        return 'word'
    }
    return 'image'
}